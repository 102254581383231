import Dashboard from "../views/Dashboard/Dashboard";
import Customers from "../views/Customers/Customers";
import InActiveCustomers from "../views/Customers/InActiveCustomers";
import ViewCustomerForm from "../views/Customers/ViewCustomerForm";
import Menu from "../views/Menu/Menu";
import AddMenu from "../views/Menu/MenuForm/AddMenu";
import EditMenu from "../views/Menu/MenuForm/EditMenu";
import ViewMenu from "../views/Menu/MenuForm/ViewMenu";
import Profile from "../views/Profile";
import Transaction from "../views/Transactions/Transactions";
import ReorderMenu from "../views/Menu/ReorderMenu";
import Tracks from "../views/Tracks/Tracks";
import AddTrack from "../views/Tracks/TrackForm/AddTrack";
import ViewTrack from "../views/Tracks/TrackForm/ViewTrack";
import EditTrack from "../views/Tracks/TrackForm/EditTrack";
import Programs from "../views/Programs/Programs";
import AddProgram from "../views/Programs/ProgramForm/AddProgram";
import ViewProgram from "../views/Programs/ProgramForm/ViewProgram";
import EditProgram from "../views/Programs/ProgramForm/EditProgram";
import ReorderTracks from "../views/Tracks/ReorderTracks";
import ReorderPrograms from "../views/Programs/ReorderPrograms";
import Reports from "../views/Report/Reports";

export const AppRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    exact: true,
  },
  {
    path: "/inactive-customers",
    name: "InActive Customers",
    component: InActiveCustomers,
    exact: true,
  },
  {
    path: "/customers",
    name: "Customers",
    component: Customers,
    exact: true,
  },
  {
    path: "/customers/view/:id",
    name: "Customers",
    component: ViewCustomerForm,
    exact: true,
  },
  {
    path: "/categories",
    name: "Categories",
    component: Menu,
    exact: true,
  },
  {
    path: "/category/reorder/",
    name: "Reorder Menu",
    component: ReorderMenu,
    exact: true,
  },
  {
    path: "/category/add/",
    name: "AddCategory",
    component: AddMenu,
    exact: true,
  },
  {
    path: "/category/view/:id",
    name: "View Category Details",
    component: ViewMenu,
    exact: true,
  },
  {
    path: "/category/edit/:id",
    name: "Edit Category Details",
    component: EditMenu,
    exact: true,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Profile,
    exact: true,
  },
  {
    path: "/tracks",
    name: "Tracks",
    component: Tracks,
    exact: true,
  },
  {
    path: "/track/add/",
    name: "Add Tracks",
    component: AddTrack,
    exact: true,
  },
  {
    path: "/track/view/:id",
    name: "View Track Details",
    component: ViewTrack,
    exact: true,
  },
  {
    path: "/track/edit/:id",
    name: "Edit Track Details",
    component: EditTrack,
    exact: true,
  },
  {
    path: "/track/reorder/",
    name: "Reorder Track",
    component: ReorderTracks,
    exact: true,
  },
  {
    path: "/programs",
    name: "Programs",
    component: Programs,
    exact: true,
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
    exact: true,
  },
  {
    path: "/program/add/",
    name: "Add Programs",
    component: AddProgram,
    exact: true,
  },
  {
    path: "/program/view/:id",
    name: "View Programs",
    component: ViewProgram,
    exact: true,
  },
  {
    path: "/program/edit/:id",
    name: "Edit Program",
    component: EditProgram,
    exact: true,
  },
  {
    path: "/program/reorder/",
    name: "Reorder Program",
    component: ReorderPrograms,
    exact: true,
  },
  {
    path: "/transactions",
    name: "Transactions",
    component: Transaction,
    exact: true,
  },
];
