import React from "react";
import TableCard from "../../shared/TableCard/TableCard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputSwitch } from "primereact/inputswitch";
import { useDispatch } from "react-redux";
import { CustomerEnableDisableAction } from "../../redux/actions/customerAction";
import TableImage from "../../shared/TableCard/TableImage";

export default function CustomerTable({
  heading,
  allCustomers,
  loading,
  history,
  search,
  setSearch,
}) {
  const dispatch = useDispatch();
  const actions = (d) => {
    return (
      <i
        className="pi pi-eye cursor-pointer"
        onClick={() => history.push(`customers/view/${d._id}`)}
      ></i>
    );
  };

  const handleSwitchChange = (id) => {
    dispatch(CustomerEnableDisableAction(id));
  };

  const isActive = (rowData) => {
    return (
      <div className="flex">
        <InputSwitch
          checked={!rowData.isDeleted}
          onChange={() => handleSwitchChange(rowData?._id)}
        />
      </div>
    );
  };

  const image = (r) => {
    const image = r.image;
    return <TableImage image={image} />;
  };

  return (
    <TableCard title="Transactions" onSearch={setSearch} searchKeyword={search}>
      <DataTable
        paginator
        value={allCustomers}
        showGridlines
        rows={10}
        loading={loading}
        emptyMessage="No transactions found."
      >
        <Column
          field="fullName"
          header="Name"
          style={{ width: "250px" }}
        ></Column>
        <Column
          field="email"
          header="Email"
          style={{ width: "250px" }}
        ></Column>
        <Column
          field="mobile"
          header="Mobile"
          style={{ width: "250px" }}
        ></Column>
        <Column body={image} header="Image" style={{ width: "80px" }}></Column>
        <Column
          header="Active"
          body={isActive}
          style={{ width: "80px" }}
        ></Column>
        <Column
          body={actions}
          style={{ width: "80px" }}
          header="Action"
        ></Column>
      </DataTable>
    </TableCard>
  );
}
